// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import * as api from '../../../config/api';

import type { User } from '../User';
import type { Tenant } from '../Tenant';
import type { Component } from '../Component';
import type { Profile } from '../Profile';

import * as redux from '../redux';

import { clear, publicApi, privateApi } from '../../../api';

import { Service } from '../../libs/services/Service';
import { storeManager } from '../../../services/redux';

export class UserService extends Service {
  private static resetable: Record<string, () => void> = {};

  static addResetable(key: string, resetable: () => void): void {
    UserService.resetable[key] = resetable;
  }

  static async login(
    username: string,
    password: string,
    args?: Record<string, unknown>,
  ): Promise<boolean> {
    try {
      const auth = await publicApi.post<{
        token_type: string;
        access_token: string;
        refresh_token: string;
      }>(
        api.compilePath(api.authTokenEndPoint),
        api.compileArgs(api.authTokenEndPoint, {
          grant_type: 'password',
          client_id: api.clientId,
          client_secret: api.clientSecret,
          username,
          password,
          scope: '*',
          ...args,
        }),
        { headers: { 'Cache-Control': 'no-cache' } },
      );

      const user = await publicApi.get<User>(
        api.compilePath(api.authAccountEndPoint),
        api.compileArgs(api.authAccountEndPoint),
        {
          headers: {
            Authorization: `${auth.token_type} ${auth.access_token}`,
            'Cache-Control': 'no-cache',
          },
        },
      ) as User;

      UserService.dispatch(redux.loginAction({
        ...user,
        token: {
          access: auth.access_token,
          refresh: auth.refresh_token,
        },
      }));

      return true;
    } catch (e) {
      // eslint-disable-next-line no-throw-literal, @typescript-eslint/no-throw-literal
      throw { code: e.response?.status };
    }
  }

  static async logout(
    user: User,
    args?: Record<string, unknown>,
  ): Promise<void> {
    try {
      await privateApi.delete<User>(
        api.compilePath(api.authTokensEndPoint, { token: user.token.access }),
        api.compileArgs(api.authTokensEndPoint, { ...args }),
        { headers: { 'Cache-Control': 'no-cache' } },
      );
    } finally {
      privateApi.defaults.headers.Authorization = null;
      privateApi.defaults.headers['X-Tenant'] = null;
      privateApi.defaults.headers['X-Component'] = null;

      UserService.dispatch(redux.logoutAction());

      clear();
      storeManager.clear();

      _.forEach(UserService.resetable, (resetable) => resetable());
    }
  }

  static async refresh(
    user: User,
    args?: Record<string, unknown>,
  ): Promise<string> {
    try {
      const result = await publicApi.post<User>(
        api.compilePath(api.authTokenEndPoint),
        api.compileArgs(api.authTokenEndPoint, {
          grant_type: 'refresh_token',
          client_id: api.clientId,
          client_secret: api.clientSecret,
          refresh_token: user.token.refresh,
          ...args,
        }),
        { headers: { 'Cache-Control': 'no-cache' } },
      );

      UserService.dispatch(redux.refreshAction(result));

      return result.token.access;
    } catch (e) {
      UserService.logout(user);

      return null;
    }
  }

  static async tenant(
    user: User,
    tenant: Tenant,
  ): Promise<Tenant> {
    UserService.dispatch(redux.tenantAction(user, tenant));

    return tenant;
  }

  static async component(
    user: User,
    component: Component,
  ): Promise<Component> {
    UserService.dispatch(redux.componentAction(user, component));

    return component;
  }

  static async profile(
    user: User,
    tenant?: Tenant,
    component?: Component,
  ): Promise<Profile> {
    clear();
    storeManager.clear();

    _.forEach(UserService.resetable, (resetable) => resetable());

    const profile = await privateApi.get<Profile>(
      api.compilePath(api.authProfileEndPoint),
      api.compileArgs(api.authProfileEndPoint),
      {
        headers: {
          'Cache-Control': 'no-cache',
          'X-Tenant': tenant?.identifiant,
          'X-Component': component?.identifiant,
        },
      },
    ) as Profile;

    UserService.dispatch(redux.profileAction(user, profile));

    return profile;
  }

  static queryUser(): [User, boolean, boolean] {
    const user = UserService.select<{ user: User }, User>(
      redux.userSelector(),
    );

    if (user) {
      privateApi.defaults.headers.Authorization = `Bearer ${user.token?.access}`;
      privateApi.defaults.headers['X-Tenant'] = user.tenant?.identifiant;
      privateApi.defaults.headers['X-Component'] = user.component?.identifiant;
    }

    return [
      user,
      false,
      false,
    ];
  }

  static isValid(user?: User): boolean {
    return !_.isNil(user) && _.has(user, 'token');
  }
}
